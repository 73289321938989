import React from "react";
import { Notification } from 'rsuite';

const showError = (message) => {
    if(message === "Failed to fetch"){
        message = "Falha de rede, verifique sua conexão com a internet"
    }
    Notification['error']({
        title: "Erro",
        description: <p>{message}</p>
    });
}

// https://dev.iugu.com/docs/tokeniza%C3%A7%C3%A3o

export default class IuguService {

    provider;

    constructor() {
        if (!window.Iugu){
            console.error("Não foi possível acessar o serviço de pagamentos, contate o suporte para mais detalhes");
        }
            // showError("Não foi possível acessar o serviço de pagamentos, contate o suporte para mais detalhes");

        this.provider = window.Iugu
    }

    createCreditCardToken(formObject){

        // formObject according to https://dev.iugu.com/docs/tokeniza%C3%A7%C3%A3o
        //https://support.iugu.com/hc/pt-br/articles/212456346-Usar-cart%C3%B5es-de-teste-em-modo-de-teste
        
        // const ccObject = this.provider.CreditCard(ccnumber, valid_month /*MM*/, valid_year /*YYYY*/, name,  cvv);

        console.log(formObject)
        return new Promise((resolve, reject) => {
            this.provider.createPaymentToken(formObject, function(response) {
                if (response.errors) {
                    reject(response.errors)
                } else {
                    resolve({response, token: response.id})
                }   
            });
        })
    }

    validateCreditCardNumber(ccNumber){
        return this.provider.utils.validateCreditCardNumber(ccNumber);
    }

    validateCreditCardCvv(cvv, brand){
        return this.provider.utils.validateCVV(cvv, brand)
    }

    validateCreditCardExpiration(month, year){
        return this.provider.utils.validateExpiration(month, year); //(MM, YYYY)
    }

    validateCreditCardExpirationByString(exp_string){
        return this.provider.utils.validateExpirationString(exp_string); // (MM/YYYY)
    }

    getBrandByCreditCardNumber(ccNumber){
        return this.provider.utils.getBrandByCreditCardNumber(ccNumber); // (MM/YYYY)
    }


}