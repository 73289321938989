import React , { useState, useEffect }from "react"
import { useParams, Link } from "react-router-dom";
import { Grid, Row, Col, Icon, Input, ErrorMessage, Button, Loader, Message, Container } from 'rsuite';

import Card from "../../components/Card.component"
import logo from '../../assets/svg/logo.svg'

import contract from "../Register/contract";
import policies from "../privacyPolicies/privacyPolicies";
import ProductService from "../../services/Product.service";
import { useLocation } from 'react-router-dom';

const TermsAndPolicies = () => {
    const location = useLocation();

    const [productId, setProductId] = useState()
    const [document, setDocument] = useState()
    const [termProduct, setTermProduct] = useState(null)
    const [productName, setProductName] = useState("")
    const [tab, setTab] = useState(0)

    const optionDocument = () => {
        switch(tab){
            case 0:
                setDocument(contract);
                break;
            case 1:
                setDocument(policies);
                break;
            case 2:
                setDocument(termProduct);
                break;
            default:
                setDocument(contract);
                break;
        }
    }

    const getTerm = async (id) => {
        const response = await new ProductService().get(`term/${id}`)
        if(response?.term){
            setTermProduct(response?.term)
        }

        if(response?.productName){
            setProductName(response?.productName)
        }
    }

    const loadParameters = () => {
        const params = new URLSearchParams(location.search);
        const _productId = params.get('productId');
        setProductId(_productId)
    }

    useEffect(() => {
        optionDocument()
    }, [tab]);
    
    useEffect(() => {
        loadParameters()
    }, []);

    useEffect(() => {
        if (productId) {
            getTerm(productId);
        }
    }, [productId]);

    return <Container>
        <Row style={{display: "flex", justifyContent: "center", marginTop: "50px"}}> 
            <Col lg={24} style={{display: "flex", flexDirection: "column",justifyContent: "center", textAlign: "center"}}>
                <h4>Termo de adesão ao produto </h4>
                <h5>{productName} </h5>
            </Col>
        </Row>
        <Row style={{display: "flex", justifyContent: "center"}}>
            <Col lg={12} xs={24}>
                <Card>
                    <Row>
                        <Col lg={24}>
                        <div style={{}}
                                dangerouslySetInnerHTML={{__html: termProduct}}
                        />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row  style={{display: "flex", justifyContent: "center", marginTop: "25px"}}>
            <Col lg={12}  style={{display: "flex", justifyContent: "center"}}>
                <Button 
                    appearance="subtle" 
                    onClick={() => window.open('https://ecompay.app/#/privacy-policies', '_blank', 'noopener,noreferrer')} 
                >
                        Política de privacidade
                </Button>
                <Button 
                    appearance="subtle"
                    onClick={() => window.open('https://ecompay.app/#/terms-of-use', '_blank', 'noopener,noreferrer')}
                >
                    Termos de Uso
                </Button>
            </Col>
        </Row>
        <Row  style={{display: "flex", justifyContent: "center", marginTop: "25px"}}>
            <Col lg={12}  style={{display: "flex", justifyContent: "center"}}>
                <img src="https://checkout.ecompay.app/static/media/logo-blue.16a57683.svg" alt="logo" width={'120px'}/>
            </Col>
        </Row>
    </Container>
}

export default TermsAndPolicies;
