import React, { useCallback, useState, useEffect } from 'react';
import { Button, Col, Icon, Input,  Row, SelectPicker, Pagination,  Panel } from "rsuite"
import { useParams, Link } from "react-router-dom";
import Card from '../../components/Card.component';
import MembersAreaService from '../../services/MembersArea.service';
import LoadingTable from '../../components/LoadingTable.component';
import Comment from './components/Comment.component';
import MyComment from './components/MyComment.component';
import AuthService from '../../services/Authentication.service';

const InfoSchoolPage = () => {

    let { id } = useParams()
    const [tab, setTab] = useState(1);
    const [coursePaginatedList, setCoursePaginatedList] = useState([]);
    const [activePage, setActivePage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [school, setSchool] = useState(null)
    const [userId, setUserId] = useState(null)

    const getSchool = async () => {
        const response = await new MembersAreaService().get(`school/${id}`)
        setSchool(response)
    }

    const getCoursesPaginatedList = async (page = 1, filters = {}) => {
        if (school?.courses?.length) {
            const courseIds = school.courses.map(course => course._id); 
            const query = { _id: { $in: courseIds } };
            filters = query
        }
        setActivePage(page)
        const response = await new MembersAreaService().post(filters,`list/course/${page}`)
        setCoursePaginatedList(response)
    }

    const navigateToPage = (number) => getCoursesPaginatedList(number, {})

    const handleTabClick = useCallback((tabNumber) => {
        setTab(tabNumber)
    }, []);

    const getTabItemsMobile = () => {
        let tabItems = [
            {label: 'Configurações', value: 1}
        ]
        return tabItems
    }

    const loadAllLists = async () => {
        await getSchool();
    };

    const fetchCourses = async () => {
        if (school) { 
            await getCoursesPaginatedList();
            setLoading(false);     
        }
    };

    const [comments, setComments] = useState([]);

    const fetchComments = async (courseId) => {
        const response = await new MembersAreaService().get(`lesson/comments/simple/teacher/panel/${courseId}`);
        return response;
    };
    
    const getUserId = async () => {
        const response = await new AuthService().getUserId()
        if(response){
            setUserId(response.user_id)
        }
    }

    const updateCommentsForCourses = async () => {
        const newComments = {};
        const courseList = coursePaginatedList?.data || [];
  
        for (const course of courseList) {
          const comments = await fetchComments(course._id);
          newComments[course._id] = comments;
        }
        setComments(newComments);
    };
    
    useEffect(() => {
        loadAllLists();
        
    }, [id]);

    useEffect(() => {
        fetchCourses()
    }, [school])

    useEffect(() => {
        updateCommentsForCourses()
    }, [coursePaginatedList]);

    useEffect(() => {
        getUserId()
    }, [])

    const [commentFormState, setCommentFormState] = useState("")

    const isCommentFormValid = () => {
        return commentFormState.trim() !== '' && replyComment !== null;
    };

    const createNewLessonComment = async (commentId, courseId) => {
        const response = await new MembersAreaService().post({ text: commentFormState, comment: commentId},`lesson/comment`)
        if(response){
            const updatedComments = await new MembersAreaService().get(
                `lesson/comments/simple/teacher/panel/${courseId}`
            );
            setComments((prevComments) => ({
                ...prevComments,
                [courseId]: updatedComments,
            }));
            setCommentFormState("")
            setReplyComment(null)
        }
    }

     const [replyComment, setReplyComment] = useState(null)

    return <>
        <Row>
            <Col lg={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Link to={'/schools/management'}><i className="fas fa-arrow-left" style={{ fontSize: '2em', color: '#575757' }}></i></Link>
                <h3 style={{marginLeft: '15px'}}>Informações da Escola</h3>
            </Col>
        </Row>

        <Row className="only-desk">
            <div style={{margin: '20px 5px', padding: '15px 5px', background: 'rgba(255, 255, 255, 0.6)', display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '100%'}}>
                    <Button appearance={tab === 1 ? 'primary' : 'subtle'} onClick={() => handleTabClick(1)}>Comentários</Button>                      
                </div>
            </div>
        </Row>

        <Row className="only-mobile" style={{marginTop: '25px'}}>
            <Col xs={24} sm={24}>
                <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={tab}
                    onChange={(value) => handleTabClick(value)}
                    data={getTabItemsMobile()} 
                style={{ width: '100%' }} />
            </Col>
        </Row>

        {
            tab === 1 ? <>
                <Row>
                    <Col md={12}>
                        <h3>Cursos</h3>
                    </Col>
                </Row>

                <Row className="table-container">
                    <Row className="table">
                        <Row style={{display: 'flex', flexDirection: 'row'}} className="list-responsive">
                            <Row className="rs-col-md-8 rs-col-xs-8">NOME</Row>
                            <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                            <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                            <Row className="rs-col-md-4 rs-col-xs-4">STATUS</Row>
                        </Row>

                        {
                            !coursePaginatedList?.data?.length && loading ? <LoadingTable /> : null
                        }

                        {
                            !coursePaginatedList?.data?.length && !loading ? <>
                                <Row>
                                    <Col lg={24}>
                                        <Card>
                                            <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum curso cadastrado</h5>
                                        </Card>
                                    </Col>
                                </Row>
                            </> : null
                        }   

                        {
                            coursePaginatedList?.data?.map((course, index) => {
                                return <Panel style={{background: 'white', marginBottom: '.7em'}} header={<Row style={{fontSize: '0.9em', display: 'flex', flexDirection: 'row'}}>
                                    <Row className="rs-col-md-8 rs-col-xs-8">
                                        {course.name}
                                    </Row>
                                    <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                                    <Row className="rs-col-md-6 rs-col-xs-6"></Row>
                                    <Row className="rs-col-md-4 rs-col-xs-4">
                                        {course.active ? <Button className="payment-status-approved"> <span>Ativo</span></Button> : <Button className="payment-status-denied"> <span>Inativo</span></Button>}
                                    </Row>
                                </Row>} collapsible>
                                    {
                                        Array.isArray(comments[course._id]) && comments[course._id].length > 0 ? <>
                                            <Card style={{background: "#d2d6dc30", maxHeight: '60vh', overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>                           
                                                {
                                                    comments[course._id]?.map((comment, index) => {
                                                        console.log('userId',userId)
                                                        return <>
                                                            {
                                                            userId === comment?.owner?._id ? <>
                                                                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <MyComment
                                                                        key={index}
                                                                        comment={comment}
                                                                        setReplyComment={setReplyComment}
                                                                    />
                                                                </Row>                                         
                                                            </> :
                                                                <Row>
                                                                    <Comment
                                                                        key={index}
                                                                        comment={comment}
                                                                        setReplyComment={setReplyComment}
                                                                    />                                           
                                                                </Row>
                                                            }
                                                        </>
                                                    })
                                                } 
                                            </Card>
                                            <Row style={{marginTop: "15px"}}>
                                                {
                                                    replyComment ? <>
                                                    <Row style={{margin: "0px 5px 10px 5px", display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                                                        <Col lg={22}>
                                                            <Row style={{background: "#d2d6dc30", textAlign: "left", padding: "10px 10px 10px 0", borderRadius: "10px"}}>                               
                                                                <Row lg={22} style={{marginLeft: "20px"}}>
                                                                    <Row style={{
                                                                        backgroundColor: replyComment?.userColor || "#000", 
                                                                        width: "10px",
                                                                        height: "100%",
                                                                        borderRadius: "10px 0 0 10px",
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: "5px",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                    <Row style={{ fontWeight: "bold", color: replyComment?.userColor, margin: "10px 10px 0 0" }}>
                                                                        {replyComment?.comment?.owner?.name}
                                                                    </Row>
                                                                    <Row style={{ fontWeight: "500", color: "#34495e", margin: "0 10px 0 0" }}>
                                                                        <p>Aula: {replyComment?.comment?.module_children?.lesson?.name}</p>
                                                                    </Row>
                                                                    <Row style={{margin: "5px 10px 10px 0"}}>
                                                                        {replyComment?.comment?.text}
                                                                    </Row>
                                                                </Row>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                                                <Icon 
                                                                    icon="close" 
                                                                    size="2x" 
                                                                    style={{ marginRight: '0' }} 
                                                                    onClick={() => {setReplyComment(null)}}
                                                                />
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    </> : null
                                                }
                                                <Row style={{margin: "0px 5px 10px 5px", display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                                                    <Col lg={22}>
                                                        <Row style={{}}>
                                                            <Input onChange={(value) => setCommentFormState(value)} value={commentFormState} componentClass="textarea" rows={1} placeholder="Digite uma mensagem" />
                                                        </Row>
                                                    </Col> 
                                                    <Col lg={2}>
                                                        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                     
                                                            <Button 
                                                                appearance="primary"
                                                                disabled={!isCommentFormValid()}
                                                                onClick={() => createNewLessonComment(replyComment?.comment?._id, course._id)} 
                                                                >
                                                                <Icon icon="page-next" style={{ marginRight: '0' }} />
                                                            </Button>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </> : 

                                        <Row>
                                            <Col lg={24}>
                                                <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhum comentario</h5>
                                            </Col>
                                        </Row>
                                        
                                    }
                                </Panel>
                            })
                        }
                    </Row>
                </Row>

                <Row style={{marginTop: '25px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Pagination
                        size="lg"
                        prev={true}
                        next={true}
                        first={true}
                        last={true}
                        ellipsis={true}
                        boundaryLinks={true}
                        pages={coursePaginatedList.pages}
                        maxButtons={5}
                        activePage={activePage}
                        onSelect={navigateToPage}
                    />
                </Row>
            </> : null
        }
    </>
}
export default InfoSchoolPage;