import React, { useState, useEffect } from "react";
import { Col, Dropdown, Row, SelectPicker } from "rsuite";
import formatMoney from "../utils/formatMoney.util";

const CurrencyConverter = ({exchangeRates = [], priceInBRL, totalText, totalLocaleText, hideSelector = false}) => {
    // const [priceInBRL, setPriceInBRL] = useState(0);
    const [convertedPrice, setConvertedPrice] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState("USD");

    const getCurrencySymbol = () => {
        switch(selectedCurrency){
            case "USD":
                return "$"
            case "EUR":
                return "€"
            case "GBP":
                return "£"
            case "CAD":
                return "C$"
            case "ARS":
                return "$"
            case "CLP":
                return "$"
            case "COP":
                return "$"
            case "PYG":
                return "₲"
            case "PEN":
                return "S/"
            case "VES":
                return "Bs."
            case "UYU":
                return "$"
            case "MXN":
                return "MX$"
            default:
                return ""
        }
    }

    useEffect(() => {
        selectCurrencyBasedOnNavigatorLanguage()
    }, []);


    useEffect(() => {
        if(hideSelector){
            setSelectedCurrency("BRL")
        }else{
            setSelectedCurrency(localStorage.getItem("selectedCurrency") || "USD")
        }
    }, [hideSelector]);

    useEffect(() => {
        localStorage.setItem("selectedCurrency", selectedCurrency);
    }, [selectedCurrency]);

    const selectCurrencyBasedOnNavigatorLanguage = () => {
        const navigatorLanguage = navigator.language;

        const savedCurrency = localStorage.getItem("selectedCurrency");

        if (savedCurrency) {
            setSelectedCurrency(savedCurrency);
        } else if (navigatorLanguage.includes("US")) {
            setSelectedCurrency("USD"); // Estados Unidos
        } else if (navigatorLanguage.includes("ES")) {
            setSelectedCurrency("EUR"); // Espanha (EUR)
        } else if (navigatorLanguage.includes("AR")) {
            setSelectedCurrency("ARS"); // Argentina
        } else if (navigatorLanguage.includes("CL")) {
            setSelectedCurrency("CLP"); // Chile
        } else if (navigatorLanguage.includes("CO")) {
            setSelectedCurrency("COP"); // Colômbia
        } else if (navigatorLanguage.includes("PY")) {
            setSelectedCurrency("PYG"); // Paraguai
        } else if (navigatorLanguage.includes("PE")) {
            setSelectedCurrency("PEN"); // Peru
        } else if (navigatorLanguage.includes("VE")) {
            setSelectedCurrency("VES"); // Venezuela
        } else if (navigatorLanguage.includes("UY")) {
            setSelectedCurrency("UYU"); // Uruguai
        } else if (navigatorLanguage.includes("MX")) {
            setSelectedCurrency("MXN"); // Uruguai
        } else {
            setSelectedCurrency("USD"); // Padrão para qualquer outro país
        }
    };

    // Função para calcular o preço convertido
    useEffect(() => {
        // console.log("exchangeRates", exchangeRates)
        // console.log("priceInBRL", priceInBRL)
        if (priceInBRL > 0 && Object.keys(exchangeRates)?.length && exchangeRates[selectedCurrency] && exchangeRates["BRL"]) {
            const rateToSelectedCurrency = exchangeRates[selectedCurrency] / exchangeRates["BRL"]; // Converte de BRL para a moeda selecionada
    
            // Converte diretamente de BRL para a moeda selecionada
            const converted = (priceInBRL * rateToSelectedCurrency)
            const roundedValue = Math.ceil(converted * 100) / 100
            setConvertedPrice(roundedValue);
        }
    }, [priceInBRL, selectedCurrency, exchangeRates]);
    

    return (
        <>
            {!hideSelector ? <>
                <SelectPicker 
                    data={[
                        {label: 'USD', value: 'USD'},
                        {label: 'EUR', value: 'EUR'},
                        {label: 'GBP', value: 'GBP'},
                        {label: 'CAD', value: 'CAD'},
                        {label: 'ARS', value: 'ARS'},
                        {label: 'CLP', value: 'CLP'},
                        {label: 'COP', value: 'COP'},
                        {label: 'PYG', value: 'PYG'},
                        {label: 'PEN', value: 'PEN'},
                        {label: 'VES', value: 'VES'},
                        {label: 'UYU', value: 'UYU'},
                        {label: 'MXN', value: 'MXN'},
                    ]} 
                    value={selectedCurrency}
                    cleanable={false}
                    searchable={false}
                    onChange={(value) => setSelectedCurrency(value)} 
                    style={{ width: 100 }}
                />

                <br />
                {convertedPrice && (
                    <Row style={{marginBottom: '5px'}}>
                        <Col lg={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <span style={{fontSize: '1.2em', fontWeight: '600', marginLeft: '10px'}}>
                                {totalLocaleText} {getCurrencySymbol()}{convertedPrice}
                            </span>
                        </Col>
                    </Row>
                )}
            </> : <>
                <Row style={{marginBottom: '5px'}}>
                    <Col lg={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <span style={{fontSize: '1em', marginLeft: '10px'}}>
                            {totalText} R${formatMoney(priceInBRL)}
                        </span>
                    </Col>
                </Row>
            </>}
        </>
    );
};

export default CurrencyConverter;
